import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63'),
	() => import('./nodes/64'),
	() => import('./nodes/65'),
	() => import('./nodes/66'),
	() => import('./nodes/67'),
	() => import('./nodes/68'),
	() => import('./nodes/69'),
	() => import('./nodes/70'),
	() => import('./nodes/71'),
	() => import('./nodes/72'),
	() => import('./nodes/73'),
	() => import('./nodes/74'),
	() => import('./nodes/75'),
	() => import('./nodes/76'),
	() => import('./nodes/77'),
	() => import('./nodes/78'),
	() => import('./nodes/79'),
	() => import('./nodes/80'),
	() => import('./nodes/81'),
	() => import('./nodes/82')
];

export const server_loads = [0,2,6];

export const dictionary = {
		"/": [18],
		"/error": [73],
		"/explore": [~74],
		"/explore/[species]/[valueChain]/[geography]": [~75,[],[17]],
		"/home": [~76],
		"/profile": [77],
		"/read": [78],
		"/read/templates/[templateId]/latest": [~80],
		"/read/[articleId]": [~79],
		"/tables": [81],
		"/unauthorized": [82],
		"/[context]": [19,[2]],
		"/[context]/analysis": [~21,[2,3]],
		"/[context]/analysis/pelagics/analysisupdate/[...articleId]": [~23,[2,3]],
		"/[context]/analysis/preview/[articleId]": [24,[2,3]],
		"/[context]/analysis/salmonids/analysisupdate/[...articleId]": [~25,[2,3,4]],
		"/[context]/analysis/salmonids/monthlyupdate": [26,[2,3,4]],
		"/[context]/analysis/salmonids/monthlyupdate/marketupdates/[...articleId]": [~27,[2,3,4]],
		"/[context]/analysis/salmonids/monthlyupdate/productionupdates/[...articleId]": [~28,[2,3,4]],
		"/[context]/analysis/salmonids/weeklyupdate/[...articleId]": [~29,[2,3,4]],
		"/[context]/analysis/shrimp/analysisupdate/[...articleId]": [~30,[2,3,5]],
		"/[context]/analysis/shrimp/monthlyupdate": [31,[2,3,5]],
		"/[context]/analysis/shrimp/monthlyupdate/marketupdates/[...articleId]": [~32,[2,3,5]],
		"/[context]/analysis/shrimp/monthlyupdate/productionupdates/[...articleId]": [~33,[2,3,5]],
		"/[context]/analysis/shrimp/monthlyupdate/tradeupdates/[...articleId]": [~34,[2,3,5]],
		"/[context]/analysis/shrimp/weeklyupdate/[...articleId]": [~35,[2,3,5]],
		"/[context]/analysis/templates/[templateId]/latest": [~36,[2,3]],
		"/[context]/analysis/[group]/[category]/[...articleId]": [~22,[2,3]],
		"/[context]/feedback": [~37,[2]],
		"/[context]/industry/analysis": [38,[2]],
		"/[context]/industry/analysis/sources-and-methodology": [40,[2]],
		"/[context]/industry/analysis/[group]/[category]/[...articleId]": [~39,[2]],
		"/[context]/industry/companies/exporters": [~41,[2]],
		"/[context]/industry/companies/on-growing-farmers": [42,[2,6]],
		"/[context]/industry/companies/on-growing-farmers/[slug]": [~43,[2,6]],
		"/[context]/industry/companies/smolt-producers": [~44,[2]],
		"/[context]/industry/performance": [45,[2,7]],
		"/[context]/industry/performance/costs-and-investments": [47,[2,7]],
		"/[context]/industry/performance/costs-and-investments/capital-expenditure": [48,[2,7,8]],
		"/[context]/industry/performance/costs-and-investments/capital-expenditure/[sample]": [~49,[2,7,8]],
		"/[context]/industry/performance/costs-and-investments/cost-development": [50,[2,7,9]],
		"/[context]/industry/performance/costs-and-investments/cost-development/[unit]": [51,[2,7,9]],
		"/[context]/industry/performance/costs-and-investments/cost-development/[unit]/[sample]": [~52,[2,7,9]],
		"/[context]/industry/performance/key-figures": [53,[2,7]],
		"/[context]/industry/performance/key-figures/balance-sheet": [54,[2,7,10]],
		"/[context]/industry/performance/key-figures/balance-sheet/[sample]": [~55,[2,7,10]],
		"/[context]/industry/performance/key-figures/income-statement": [56,[2,7,11]],
		"/[context]/industry/performance/key-figures/income-statement/[sample]": [~57,[2,7,11]],
		"/[context]/industry/performance/key-figures/key-figures": [58,[2,7,12]],
		"/[context]/industry/performance/key-figures/key-figures/[sample]": [~59,[2,7,12]],
		"/[context]/industry/performance/operating": [60,[2,7]],
		"/[context]/industry/performance/operating/ebit": [61,[2,7,13]],
		"/[context]/industry/performance/operating/ebit/[unit]": [62,[2,7,13]],
		"/[context]/industry/performance/operating/ebit/[unit]/[sample]": [~63,[2,7,13]],
		"/[context]/industry/performance/operating/harvest-quantity": [64,[2,7,14]],
		"/[context]/industry/performance/operating/harvest-quantity/[sample]": [~65,[2,7,14]],
		"/[context]/industry/performance/operating/mab-utilisation": [66,[2,7,15]],
		"/[context]/industry/performance/operating/mab-utilisation/[sample]": [~67,[2,7,15]],
		"/[context]/industry/performance/[group]/[indicator]": [~46,[2,7]],
		"/[context]/resources/abbreviations": [~68,[2,16]],
		"/[context]/resources/definitions": [~69,[2,16]],
		"/[context]/resources/product-categories": [~70,[2,16]],
		"/[context]/resources/weight-conversions": [~71,[2,16]],
		"/[context]/upgrade": [~72,[2]],
		"/[context]/[menu]/[species]/[valueChain]/[geography]": [20,[2]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';